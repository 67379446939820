<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If
        <number-value :value="molN2" unit="\text{mol}" />
        of
        <chemical-latex content="N2(g)" />
        and
        <number-value :value="molH2" unit="\text{mol}" />
        of
        <chemical-latex content="H2(g)" />
        were placed in a
        <number-value :value="volume" unit="\text{L}" />
        container and underwent the reaction shown below:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="N2(g) + 3 H2(g) <=> 2 NH3(g)" />
      </p>

      <p class="mb-3">
        Determine the equilibrium constant,
        <stemble-latex content="$\text{K}_\text{c},$" />
        if there is
        <number-value :value="molN2eq" unit="\text{mol}" />
        of
        <chemical-latex content="N2(g)" />
        remaining once the reaction reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question117',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    molN2() {
      return this.taskState.getValueBySymbol('molN2').content;
    },
    molH2() {
      return this.taskState.getValueBySymbol('molH2').content;
    },
    molN2eq() {
      return this.taskState.getValueBySymbol('molN2eq').content;
    },
  },
};
</script>
